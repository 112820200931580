.card .card-header {
  padding: 15px 15px 0;
  background-color: #fff;
  border-bottom: none !important;
  display: flex;
  justify-content: space-between;
}
.card .card-header button {
  color: #333 !important;
  font-weight: bold;
}
