.rtl-active {
    text-align: right;

    .dropdown-menu-right {
        left: 0;
        right: auto;
    }

    .custom-switch .custom-control-label {
        width: 100%;
    }

    .noUi-connect,
    .noUi-origin {
        left: auto;
        right: 0;
        -ms-transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }

    .noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
        left: auto;
        right: -3px;
    }

    .react-select.info .react-select__multi-value .react-select__multi-value__remove {
        border-left: 1px solid #11bfe3;
        border-right: 0;
    }

    .form-check-radio .form-check-sign::before {
        font-family: "Font Awesome 5 Free";
        content: "\f111";
    }

    .form-check-radio input[type="radio"]:checked + .form-check-sign::after {
        font-family: "Font Awesome 5 Free";
        content: "\f058";
    }

    .form-check .form-check-input:disabled + .form-check-sign::after,
    .form-check .form-check-input:disabled + .form-check-sign::before,
    .form-check .form-check-input:disabled:checked + .form-check-sign::after,
    .radio input[type="radio"]:disabled + label {
        color: #ddd;
        pointer-events: none;
    }

    .form-check-radio,
    .from-check {
        margin-bottom: 0;
    }

    .card-wizard {
        .moving-tab {
            left: 0;
            right: auto;
        }
        .nav-pills {
            flex-direction: row-reverse;
        }
    }

    .rna-container .alert button.close span {
        margin-top: 0;
    }
}
